import { useMutation } from '@apollo/client'
import {
  Box,
  Button,
  Center,
  Container,
  FormControl,
  HStack,
  Icon,
  Image,
  Input,
  InputGroup,
  InputRightElement,
  Link,
  List,
  ListItem,
  Text,
  VStack,
} from '@chakra-ui/react'
import { default as React, useContext, useEffect, useState } from 'react'
import { FaEye, FaEyeSlash } from 'react-icons/fa'
import { useNavigate } from 'react-router-dom'
import basta_logo_white from 'src/assets/images/basta_logo_white.png'
import { AuthContext } from 'src/utils/auth'
import { surveyPrograms } from 'src/utils/constants'
import { SIGN_UP, LOG_IN } from 'src/utils/mutations'

export default function SignUp() {
  const [email, setEmail] = useState('')
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [error, setError] = useState<string[]>([])
  const [password, setPassword] = useState('')
  const [showReenter, setShowReenter] = React.useState(false)
  const [showPass, setShowPass] = React.useState(false)
  const [rePassword, setRePassword] = useState('')
  const [programId, setProgramId] = useState<string | null>('')
  const { updateToken, setUser } = useContext(AuthContext)
  const navigate = useNavigate()
  function isValidEmail(email: string) {
    return /\S+@\S+\.\S+/.test(email)
  }
  const [login] = useMutation(LOG_IN, {
    onCompleted: (data) => {
      localStorage.setItem('auth-token', data.tokenAuth.token)
      localStorage.setItem('user', JSON.stringify(data.tokenAuth.user))
      updateToken(data.tokenAuth.token)
      setUser(data.tokenAuth.user)
      if (data.tokenAuth.user.program.programType.toLowerCase() === surveyPrograms.discovery) {
        navigate(`/survey/seekr?email=${encodeURIComponent(email)}`)
        return
      }
      navigate('/dashboard')
    },
    onError: (error) => {
      setError(['Could not log in. Try again.'])
      console.error(error.networkError)
    },
  })
  const [signUp, { error: signUpError }] = useMutation(SIGN_UP, {
    onCompleted: (data) => {
      if (!signUpError) {
        login({
          variables: {
            email: email,
            password: password,
          },
        })
      }
    },
    onError: (error) => {
      if (error.graphQLErrors?.[0]?.message.includes('email')) {
        setError(['Email is already in use. Please use another email.'])
      }
      if (error.graphQLErrors?.[0]?.message.includes('password is too short')) {
        setError([
          'Password is too short. Please choose a password that is at least 8 characters long.',
        ])
      }
      console.error(error.networkError)
    },
  })

  const isInvalid = () => {
    setError([])
    let allErrors = []
    if (firstName.length <= 0) allErrors.push('Please enter your first name.')
    if (lastName.length <= 0) allErrors.push('Please enter your last name.')
    if (!isValidEmail(email)) allErrors.push('Please enter valid email.')
    if (password.length <= 0) allErrors.push('Please enter a password.')
    if (password !== rePassword) allErrors.push('Passwords do not match.')
    setError(allErrors)
    if (allErrors.length > 0) return true
    return false
  }

  const handleSignup = (e: any) => {
    e.preventDefault()
    if (isInvalid()) return
    signUp({
      variables: {
        email,
        firstName,
        lastName,
        password,
        programId,
      },
    })
  }

  const handleReenterClick = () => setShowReenter(!showReenter)
  const handlePassClick = () => setShowPass(!showPass)

  useEffect(() => {
    const urlString = window.location.search
    const urlParams = new URLSearchParams(urlString)
    setProgramId(urlParams.get('program'))
  }, [])

  return (
    <>
      <Box className="border-b-1 border-indigo-600">
        <Image mt={25} ml={5} w="135px" src={basta_logo_white} alt={'Basta logo'} />
      </Box>
      <Center>
        <Box mt={150} w={'md'}>
          <Center mb={3}>
            <VStack mb={6}>
              <Text
                fontFamily={'Inter'}
                fontWeight={'bold'}
                fontSize={'4xl'}
                data-testid="signup-title"
              >
                Welcome to Discovery
              </Text>
              <Text>Get started by creating your Seekr account</Text>
            </VStack>
          </Center>

          <form onSubmit={handleSignup}>
            <FormControl>
              <HStack>
                <Input
                  id="first-name"
                  placeholder="First Name"
                  mb={3}
                  overflow={'scroll'}
                  color={'#000000'}
                  _placeholder={{ opacity: 1, color: 'brand.lightBlue' }}
                  bg={'white'}
                  value={firstName}
                  onChange={(e) => {
                    setFirstName(e.target.value)
                  }}
                  resize={'horizontal'}
                />
                <Input
                  id="last-name"
                  placeholder="Last Name"
                  mb={3}
                  overflow={'scroll'}
                  color={'#000000'}
                  _placeholder={{ opacity: 1, color: 'brand.lightBlue' }}
                  bg={'white'}
                  value={lastName}
                  onChange={(e) => {
                    setLastName(e.target.value)
                  }}
                  resize={'horizontal'}
                />
              </HStack>

              <Input
                mb={3}
                color={'#000000'}
                _placeholder={{ opacity: 1, color: 'brand.lightBlue' }}
                bg={'white'}
                value={email}
                onChange={(e) => {
                  setError([])
                  setEmail(e.target.value)
                }}
                placeholder="Email"
                data-testid="email"
              />
            </FormControl>
            <FormControl>
              <InputGroup size="md">
                <Input
                  type={showPass ? 'text' : 'password'}
                  id="password"
                  color={'#000000'}
                  _placeholder={{ opacity: 1, color: 'brand.lightBlue' }}
                  bg={'white'}
                  value={password}
                  placeholder="Password"
                  onChange={(e) => setPassword(e.target.value)}
                  mb={3}
                />

                <InputRightElement width="4.5rem">
                  <Button h="1.75rem" color={'#000000'} size="sm" onClick={handlePassClick}>
                    {showPass ? <Icon as={FaEyeSlash} /> : <Icon as={FaEye} />}
                  </Button>
                </InputRightElement>
              </InputGroup>

              <InputGroup size="md">
                <Input
                  type={showReenter ? 'text' : 'password'}
                  id="reenter password"
                  color={'#000000'}
                  _placeholder={{ opacity: 1, color: 'brand.lightBlue' }}
                  bg={'white'}
                  value={rePassword}
                  placeholder="Re-enter Password"
                  onChange={(e) => setRePassword(e.target.value)}
                  mb={3}
                />

                <InputRightElement width="4.5rem">
                  <Button h="1.75rem" color={'#000000'} size="sm" onClick={handleReenterClick}>
                    {showReenter ? <Icon as={FaEyeSlash} /> : <Icon as={FaEye} />}
                  </Button>
                </InputRightElement>
              </InputGroup>
            </FormControl>
            {error.length > 0 && (
              <Container maxW={'sm'}>
                <List>
                  {error.map((item, i) => (
                    <ListItem key={i} color="red">
                      {item}
                    </ListItem>
                  ))}
                </List>
              </Container>
            )}
            <Button
              w={'100%'}
              my={3}
              backgroundColor={'#45A1DC'}
              color={'white'}
              type="submit"
              colorScheme="blue"
              data-testid="signup-btn"
            >
              Join
            </Button>
          </form>
          <Center>
            <Text mr={1}>Already have an account? </Text>
            <Link href="/log-in">
              <Text fontWeight={'bold'} as={'u'}>
                Log In
              </Text>
            </Link>
          </Center>
        </Box>
      </Center>
    </>
  )
}
